
import { Component, Prop } from 'vue-property-decorator';
import BrandingForm from '@/components/branding/BrandingForm.vue';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import { Branding } from '@govflanders/mbp-admin-panel-shared';
import { cloneDeep } from 'lodash';
import ValidationNotification from '@/components/shared/ValidationNotification.vue';
import { mixins } from 'vue-class-component';
import PendingChangesGuard from '@/utility/forms/PendingChangesGuard.vue';
import RoleGuard from '@/utility/auth/RoleGuard.vue';
import { getContrastMessages } from '@/utility/branding';

@Component({
  components: {
    Anchor,
    PageHeader,
    PageHeaderAction,
    PageHeaderNavigationAction,
    BrandingForm,
    ValidationNotification,
  },
})
export default class BrandingEdit extends mixins(PendingChangesGuard, RoleGuard) {
  @Prop({
    type: String,
    required: true,
  })
  public id!: string;

  public value: Branding = null;
  public initialValue: Branding = null;
  public valueLoading: boolean;
  public error = '';

  private created() {
    this.valueLoading = true;
    this.$store
      .dispatch('branding/get', this.id)
      .then((branding) => {
        this.value = cloneDeep(branding);
        this.initialValue = cloneDeep(branding);
        this.valueLoading = false;
      })
      .catch((e) => console.log(e));
  }

  public get contrastMessages() {
    const primary = this.value?.colors?.primary;
    const functional = this.value?.colors?.functional;

    return getContrastMessages(primary, functional);
  }

  public revert() {
    this.value = cloneDeep(this.initialValue);
  }

  public save() {
    this.$store
      .dispatch('branding/update', this.value)
      .then((updatedValue) => {
        this.value = updatedValue;
        this.initialValue = cloneDeep(updatedValue);
        this.$toast.success(this.$t('branding.edit.save-success.title'));
      })
      .catch((e) => {
        console.error(e);
        this.$toast.error(this.$t('branding.edit.save-error.title'));
      });
  }
}
